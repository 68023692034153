<template>
  <BaseModal
    :id="id"
    :confirm-text="$t('Copy and Continue')"
    :disable-confirm-button="loading"
    :on-confirm="copy"
    :heading="heading"
    :de-tracking-name-continue="'copy-modal_copy-and-continue-button'"
    :de-tracking-name-cancel="'copy-modal_cancel-button'"
    :de-tracking-name-close="'copy-modal_close-button'"
  >
    <CometBlockMessage
      block-message-type="error"
      v-if="error"
    >
      <p>{{ error }}</p>
    </CometBlockMessage>
    <p>
      {{ bodyText }}
    </p>
  </BaseModal>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import BaseModal from '@/components/modals/BaseModal.vue';
import CometBlockMessage from '@/components/ui-elements/CometBlockMessage.vue';
import * as types from '@/lib/constants/store';
import experimentData from '@/mixins/experimentData';
import {
  useEditorStore,
  useModalStore,
} from '@/stores';

export default {
  name: 'CopyCreationModal',
  components: {
    BaseModal,
    CometBlockMessage,
  },
  props: {
    id: {
      type: String, // used for opening and closing modal
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  mixins: [experimentData],
  data() {
    return {
      error: null,
      loading: false,
    };
  },
  computed: {
    ...mapState(useEditorStore, [
      'domains',
      'draft',
      'studioAppIsRunning',
    ]),
    bodyText() {
      if (this.type === 'lesson' && this.isExperimentCopyJanVariationControl) {
        return this.$t('To edit this lesson, first create a copy and add it to your Studio Dashboard.');
      }

      if (this.isExperimentCopyJanVariationControl) {
        return this.type === 'board'
          ? this.$t('To edit this board, first create a copy and add it to your Studio Dashboard.')
          : this.$t('To edit this slideshow, first create a copy and add it to your Studio Dashboard.');
      }

      if (this.type === 'lesson') {
        return this.$t('Copy the lesson and add it to your Studio dashboard.');
      }

      return this.type === 'board'
        ? this.$t('Copy the board and add it to your Studio dashboard.')
        : this.$t('Copy the slideshow and add it to your Studio dashboard.');
    },
    heading() {
      if (this.isExperimentCopyJanVariationControl) {
        return this.$t('Create a Copy?');
      }

      if (this.type === 'lesson') return this.$t('Copy lesson');

      return this.type === 'board' ? this.$t('Copy board') : this.$t('Copy slideshow');
    },
    type() {
      return this.draft.options.type;
    },
    isExperimentCopyJanVariationControl() {
      return this.getExperimentData('variant-1-studio-copy-experiment-jan-23').variation_is_control;
    },
  },
  methods: {
    ...mapActions(useModalStore, [
      types.CLOSE_MODAL,
    ]),
    ...mapActions(useEditorStore, [
      types.COPY_CREATION,
    ]),
    async copy() {
      this.error = null;
      this.loading = true;
      try {
        const newDraft = await this[types.COPY_CREATION]({
          type: this.options.status || 'creation',
          id: this.draft.id,
        });

        // New drafts always have a single reference
        const draftReference = newDraft.references[0];

        if (this.studioAppIsRunning) {
          this.$router.push({
            path: '/edit',
            query: { id: draftReference.reference_id },
          });
        } else {
          const studioUrl = `${this.domains.studioDomain}/edit?id=${draftReference.reference_id}`;
          window.location.href = studioUrl;
        }

        this[types.CLOSE_MODAL]({ id: this.id });

        if (this.options.successCallback) {
          this.options.successCallback();
        }
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
